<template>
	<section
		class="notifications-container"
		:class="{ collapsed: isCollapsed }"
		data-cy="notification-list"
	>
		<transition-group name="fade">
			<!-- Notification -->
			<v-notifications-notification
				v-for="(notification, index) in pageNotifications"
				:key="notification.id"
				v-bind="notification"
				:index="index"
				:is-most-recent-notification="index === pageNotifications.length - 1"
				:past-notification-stack-threshold="pastNotificationStackThreshold"
				@toggle-collapse="preventCollapse = !preventCollapse"
				@dismiss="handleDismiss($event)"
				@dismiss-all="handleDismiss($event, true)"
			/>
		</transition-group>
	</section>
</template>

<script>
import { mapState } from 'vuex'
import VNotificationsNotification from './VueNotificationsNotification'

export default {
	name: 'VNotifications',
	components: {
		VNotificationsNotification,
	},
	data() {
		return {
			preventCollapse: false,
		}
	},
	computed: {
		...mapState({
			pageNotifications: state => state.VueNotifications.pageNotifications,
		}),
		isCollapsed() {
			return !this.preventCollapse && this.pastNotificationStackThreshold
		},
		pastNotificationStackThreshold() {
			return this.pageNotifications.length > 3
		},
	},
	watch: {
		// Reset `preventCollapse` when all of our notifications are gone
		pageNotifications(pageNotifications) {
			if (pageNotifications.length === 0) {
				this.preventCollapse = false
			}
		},
	},
	methods: {
		handleDismiss(notificationId, dismissAll) {
			if (dismissAll && this.isCollapsed) {
				const notificationIds = this.pageNotifications.map(notification => notification.id)
				notificationIds.forEach(id => this.$store.commit('removeNotification', id))
			} else {
				this.$store.commit('removeNotification', notificationId)
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

$notification-width: 330px;

.notifications-container {
	position: fixed;
	bottom: 0;
	right: -4px;
	z-index: $zi-alert;
	width: $notification-width;
	@media (min-width: $mqSmall) {
		right: 16px;
		bottom: 16px;
	}
}
</style>
